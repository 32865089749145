import React from "react"
import styled from "styled-components"
import SectionHeading from "./styling/section-heading"
import SecureNewTabLink from "./utils/secure-new-tab-link"
import EmailLink from "./utils/email-link"

const StyledContactSection = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`

const StyledContactButtonContainer = styled.div`
  padding-top: 50px;
  display: flex;
  max-width: 300px;
  margin: 0 auto;
  justify-content: space-around;
`

const Contact = ({ data }) => (
  <StyledContactSection>

    <SectionHeading heading="Contact"
                    scrollId="contact" />

    <p>{data.frontmatter.paragraph}</p>

    <StyledContactButtonContainer>

      <EmailLink email={data.frontmatter.email}>
        <button className="gradient-button">
          Mail
        </button>
      </EmailLink>

      <SecureNewTabLink destination="https://www.linkedin.com/in/tim-grein/">
        <button className="gradient-button-inverted">
          LinkedIn
        </button>
      </SecureNewTabLink>

    </StyledContactButtonContainer>

  </StyledContactSection>
)

export default Contact
