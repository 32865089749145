import React from "react"
import styled from "styled-components"
import SecureNewTabLink from "./utils/secure-new-tab-link"
import useMediaQuery from "./styling/use-media-query-hook"
import { device } from "./styling/device"


const StyledSection = styled.section`
  .button-container {
    margin-top: 50px;

    button {
      margin-right: 25px;
    }
  }

  @media ${device.mobileL} {
    .button-container {
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-top: 15px;
      }
    }
  }
`

const StyledGreetings = styled.h2`
  margin: 0;
`

const StyledTitle = styled.h1`
  font-size: 3.5rem;
  line-height: 3.6rem;
  margin: 0;
`

const StyledSubtitle = styled.h2`
  margin: 0;
`

const StyledDescription = styled.div`
  max-width: 600px;
  font-size: 1rem;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #626262;
`

const Hero = ({ data }) => {
  const isMobile = useMediaQuery(device.mobileL)

  const { frontmatter } = data
  const style = {
    paddingTop: isMobile ? "6rem" : "10rem"
  }

  return (
    <StyledSection id="hero" style={style}>

      <StyledGreetings>
        {frontmatter.greetings}
      </StyledGreetings>

      <StyledTitle className="gradient-text">
        {frontmatter.name}
      </StyledTitle>

      <StyledSubtitle>
        {frontmatter.subtitle}
      </StyledSubtitle>

      <StyledDescription>
        {frontmatter.description}
      </StyledDescription>

      <div className="button-container">

        <SecureNewTabLink destination="https://www.linkedin.com/in/tim-grein/">
          <button className="gradient-button-inverted">
            LinkedIn
          </button>
        </SecureNewTabLink>

        <SecureNewTabLink destination="https://github.com/timgrein">
          <button className="gradient-button-inverted">
            GitHub
          </button>
        </SecureNewTabLink>

      </div>

    </StyledSection>
  )
}

export default Hero
