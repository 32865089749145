import React from "react"
import styled from "styled-components"

const StyledDivider = styled.div`
  border: 0;
  height: 2px;
  margin: ${props => props.verticalMargin}px 0px;
  overflow: visible;
  text-align: center;
  background-image: linear-gradient(to right,
  transparent,
  var(--primary),
  transparent);
`

const HorizontalDivider = ({verticalMargin}) => {
  return (
    <StyledDivider verticalMargin={verticalMargin}/>
  )
}

export default HorizontalDivider;
