import React from 'react';

const EmailLink = ({email, children}) => {
  return (
    <a href={"mailto:" + email} rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default EmailLink;
