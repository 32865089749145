import React, { useState } from "react"
import Timeline from "./timeline"
import SectionHeading from "../styling/section-heading"
import TimelineSwitchButtons from "./timeline-switch-buttons"
import { faBriefcase, faGraduationCap, faStamp } from "@fortawesome/free-solid-svg-icons"

const Experience = ({ experience }) => {
  const { academicExperience, workExperience, certificatesExperience } = experience

  const [state, setState] = useState({
    currentTimeline: 'Academic'
  })

  const handleSwitch = (newTimeline) => {
    setState({
      ...state,
      currentTimeline: newTimeline
    })
  }

  const isAcademicActive = state.currentTimeline === "Academic"
  const isWorkActive = state.currentTimeline === "Work"
  const isCertificatesActive = state.currentTimeline === "Certificates"

  const buttons = [
    {
      activeCondition: isAcademicActive,
      icon: faGraduationCap,
      text: "Academic",
      hasElements: academicExperience.nodes.length > 0
    },
    {
      activeCondition: isWorkActive,
      icon: faBriefcase,
      text: "Work",
      hasElements: workExperience.nodes.length > 0
    },
    {
      activeCondition: isCertificatesActive,
      icon: faStamp,
      text: "Certificates",
      hasElements: certificatesExperience.nodes.length > 0
    }
  ]

  return (
    <div>

      <SectionHeading heading={"Experience"}
                      scrollId="experience"/>

      <TimelineSwitchButtons handleSwitch={handleSwitch}
                             buttonInfos={buttons}
                             enabledButton={state.currentTimeline}/>

      {isAcademicActive &&
        <Timeline experience={academicExperience}
                  hasTranscripts/>}

      {isWorkActive &&
        <Timeline experience={workExperience} />}

      {isCertificatesActive &&
        <Timeline experience={certificatesExperience}
                  hasCertificates/>}

    </div>
  )
}

export default Experience
