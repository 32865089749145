import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/styling/layout"
import Hero from "../components/hero"
import Experience from "../components/experience/experience"
import Projects from "../components/projects/projects"
import HorizontalDivider from "../components/styling/horizontal-divider"
import About from "../components/about"
import Contact from "../components/contact"

const IndexPage = ({ data }) => {


  return (
    <Layout>

      <Hero data={data.hero.nodes[0]} />
      <HorizontalDivider verticalMargin={75} />

      <About data={data.about.nodes[0]} />
      <HorizontalDivider verticalMargin={75} />

      <Experience experience={data} />
      <HorizontalDivider verticalMargin={75} />

      <Projects projects={data.projects} />
      <HorizontalDivider verticalMargin={75} />

      <Contact data={data.contact.nodes[0]} />
      <HorizontalDivider verticalMargin={75} />

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    hero: allMdx(filter: {fileAbsolutePath: {regex: "/(hero)/"}}) {
      nodes {
        frontmatter {
          greetings
          name
          subtitle
          description
        }
      }
    }
    about: allMdx(filter : {fileAbsolutePath: {regex: "/(about)/"}}) {
      nodes {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body
      }
    }
    academicExperience: allMdx(
      filter: {fileAbsolutePath: {regex: "/(experience/academic)/"}}
      sort: {fields: fileAbsolutePath, order: DESC}) {
      nodes {
        frontmatter {
          title
          station
          start
          end
          courseWork {
            name
            grade
          }
       }
       body
      }
    }
    workExperience: allMdx(
      filter: {fileAbsolutePath: {regex: "/(experience/work)/"}}
      sort: {fields: fileAbsolutePath, order: DESC}) {
      nodes {
        frontmatter {
          title
          station
          start
          end
        }
        body
      }
    }
    certificatesExperience: allMdx(
      filter: {fileAbsolutePath: {regex: "/(experience/certificates)/"}}
      sort: {fields: fileAbsolutePath, order: DESC}) {
      nodes {
        frontmatter {
          title
          station
          start
          end
        }
        body
      }
    }
    projects: allMdx(filter: {fileAbsolutePath: {regex: "/(projects)/"}}) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          githubLink
          technologies
          keywords
        }
        body
      }
    }
    contact: allMdx(filter: {fileAbsolutePath: {regex: "/(contact)/"}}){
      nodes {
        frontmatter {
          email
          paragraph
        }
      }
    }
  }
`
