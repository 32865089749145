import React from "react"
import styled from "styled-components"
import HorizontalDivider from "../../styling/horizontal-divider"

import { getCurrentMonthAndYear } from "../../../utils/TimeUtils"
import { faPenFancy } from "@fortawesome/free-solid-svg-icons"
import Signature from "../signature"

const StyledTranscriptOuterContainer = styled.div`
  border: 3px solid var(--primary);
  padding: 2px;
  border-radius: 15px;
  max-width: 375px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09),
  0 2px 2px rgba(0, 0, 0, 0.09),
  0 4px 4px rgba(0, 0, 0, 0.09),
  0 8px 8px rgba(0, 0, 0, 0.09),
  0 16px 16px rgba(0, 0, 0, 0.09);
  margin: 50px auto;
`

const StyledTranscriptContainer = styled.div`
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  padding: 25px;
  border: 3px solid var(--primary);
`

const StyledCourseGradeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  padding-bottom: 15px;
  font-weight: 700;
`

const StyledGradeLine = styled.div`
  font-size: 0.8rem;
  color: #444444;
  text-align: left;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`

const StyledCourseName = styled.span`
`

const StyledGrade = styled.span`
  font-weight: 700;
`

const StyledSignatureContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
`

const Transcript = ({ data }) => {
  return (
    <StyledTranscriptOuterContainer>

      <StyledTranscriptContainer>

        <h4>Noteable Course Work</h4>

        <h3 className="gradient-text">{data.title}</h3>

        <h5>{data.station}</h5>

        <HorizontalDivider verticalMargin="30" />

        {data.courseWork && <StyledCourseGradeHeaderContainer>
          <span>Course</span>
          <span>Grade</span>
        </StyledCourseGradeHeaderContainer>
        }

        {data.courseWork && data.courseWork.map(course => (
          <StyledGradeLine key={course.name}>

            <StyledCourseName>
              {course.name}
            </StyledCourseName>

            <StyledGrade>
              {course.grade}
            </StyledGrade>

          </StyledGradeLine>
        ))}

        <StyledSignatureContainer>
          <Signature content={getCurrentMonthAndYear()}
                     icon={faPenFancy} />
        </StyledSignatureContainer>

      </StyledTranscriptContainer>

    </StyledTranscriptOuterContainer>
  )
}

export default Transcript
