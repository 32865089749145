import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { device } from "../styling/device"

const StyledIcon = styled(props => <FontAwesomeIcon {...props} />)`
  margin-right: 10px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 75px;

  button {
    margin-right: 15px;
    margin-left: 15px;
  }

  @media ${device.mobileL} {
    flex-direction: column;

    button {
      margin: 10px 0;
    }
  }
`

const TimelineSwitchButtons = ({ handleSwitch, buttonInfos }) => {
  return (
    <StyledButtonContainer>
      {buttonInfos.map(button => (
        button.hasElements &&
        <button
          key={button.text}
          onClick={() => handleSwitch(button.text)}
          className={button.activeCondition ? "gradient-button" : ""}
          disabled={button.activeCondition}>
          <StyledIcon icon={button.icon} />
          {button.text}
        </button>
      ))}
    </StyledButtonContainer>
  )
}

export default TimelineSwitchButtons
