import React from 'react'


const SecureNewTabLink = ({destination, children}) => {
  return (
    <a href={destination} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export default SecureNewTabLink;
