import React, { Fragment } from "react"
import styled from "styled-components"
import HorizontalDivider from "../styling/horizontal-divider"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Transcript from "./academic/transcript"
import Certificate from "./certificates/certificate"
import { device } from "../styling/device"

const StyledTimeLine = styled.div`
  // source: https://codepen.io/letsbleachthis/pen/YJgNpv

  main {
    min-width: 300px;
    max-width: 500px;
    margin: auto;
  }

  section {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    border-image: linear-gradient(to right,
    var(--primaryLight) 0%,
    var(--primary) 100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 50px;
    position: relative;
    color: black;
    
    @media ${device.mobileL} {
      padding: 25px;
    }

    //numbers
    &:before {
      content: " ";
      position: absolute;
      border-radius: 50%;
      padding: 5px;
      height: 1.25em;
      width: 1.25em;
      background-color: white;
      text-align: center;
      line-height: 1.25em;
      color: black;
      font-size: 1em;
      border-width: 3px;
      border-style: solid;
    }
  }

  section:nth-child(odd) {
    border-right: 3px solid;
    padding-left: 0;

    &:before {
      border-color: var(--primary);
      left: 100%;
      margin-left: -18px;
    }
  }

  section:nth-child(even) {
    border-left: 3px solid;
    padding-right: 0;

    &:before {
      right: 100%;
      margin-right: -18px;
      border-color: var(--primaryLight);
    }
  }

  section:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  section:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  h5 {
    color: #818181;
  }

  .timespan {
    color: #818181;
  }

  button {

    &:first-child {
      margin-right: 40px;
    }

    &:disabled {
      cursor: default;
    }
  }
`

const StyledText = styled.div`
  font-size: 1rem;
`

const Timeline = ({ experience, hasTranscripts = false, hasCertificates = false }) => {

  return (
    <StyledTimeLine>

      <main>
        {experience.nodes.map(node => (
          <section key={node.frontmatter.title}>

            {!hasCertificates &&
            <Fragment>
              <h3>{node.frontmatter.title}</h3>

              <h5 className="timespan"> ({node.frontmatter.start} - {node.frontmatter.end})</h5>

              <h4>{node.frontmatter.station}</h4>

              <HorizontalDivider verticalMargin={10} />
            </Fragment>
            }


            <StyledText>
              {!hasCertificates &&
              <MDXRenderer>
                {node.body}
              </MDXRenderer>}

              {hasTranscripts && <Transcript data={node.frontmatter} />}

              {hasCertificates && <Certificate data={node} />}

            </StyledText>

          </section>
        ))}
      </main>

    </StyledTimeLine>
  )
}

export default Timeline
