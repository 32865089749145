import React from 'react'
import styled from 'styled-components'

const StyledProjectCounter = styled.div`
  text-align: center;
  color: gray;
  padding-bottom: 60px;
`

const ProjectCounter = ({numberOfCurrentProjects, numberOfAllProjects}) => (
  <StyledProjectCounter>
    (Showing {numberOfCurrentProjects} of {numberOfAllProjects} projects)
  </StyledProjectCounter>
)

export default ProjectCounter;
