import React from "react"

import styled from "styled-components"

import { getImage } from "gatsby-plugin-image"

import SecureNewTabLink from "../utils/secure-new-tab-link"

import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { device } from "../styling/device"
import ImageWithLink from "../utils/image-with-link"
import useMediaQuery from "../styling/use-media-query-hook"


const StyledProjectCard = styled.div`

  padding: 50px 0;

  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 25px 0;
    align-items: center;
  }
`

const StyledTechnologiesContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  color: gray;
`

const StyledGitHubLinkContainer = styled.div`
  padding-top: 15px;

  .icon {
    font-size: 1.8rem;
  }

  &:hover {
    color: #383838;
  }
`

const StyledProjectText = styled.div`
  padding: 15px;
  max-width: 450px;
  font-size: 1rem;
`

const imageStyle = {
  borderRadius: '15px',
  width: '400px',
  height: '250px',
  boxShadow: '0 2px 1px rgba(0, 0, 0, 0.09),\n' +
    '0 4px 2px rgba(0, 0, 0, 0.09),\n' +
    '0 8px 4px rgba(0, 0, 0, 0.09),\n' +
    '0 16px 8px rgba(0, 0, 0, 0.09),\n' +
    '0 32px 16px rgba(0, 0, 0, 0.09)'

}

const imageStyleMobile = {
  margin: '0 auto',
  maxWidth: '350px',
  maxHeight: '200px'
}

const ProjectCard = ({ projectData, imageRight }) => {
  const { title, githubLink, technologies } = projectData.frontmatter
  const image = getImage(projectData.frontmatter.image)
  const technologiesString = technologies.join(", ")
  const isMobileL = useMediaQuery(device.mobileL)

  return (
    <StyledProjectCard>
      {!imageRight &&
      <ImageWithLink destination={githubLink}
                     image={image}
                     alt={title}
                     style={isMobileL ? imageStyleMobile : imageStyle} />
      }

      <StyledProjectText>
        <h3>{title}</h3>

        <MDXRenderer>
          {projectData.body}
        </MDXRenderer>

        <StyledTechnologiesContainer>
          Technologies: {technologiesString}
        </StyledTechnologiesContainer>

        {githubLink &&
        <StyledGitHubLinkContainer>
          <SecureNewTabLink destination={githubLink}>
            <FontAwesomeIcon className="icon" icon={faGithub} />
          </SecureNewTabLink>
        </StyledGitHubLinkContainer>}

      </StyledProjectText>

      {imageRight &&
          <ImageWithLink destination={githubLink}
                         image={image}
                         alt={title}
                         style={isMobileL ? imageStyleMobile : imageStyle} />}
    </StyledProjectCard>
  )
}

export default ProjectCard
