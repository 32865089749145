import React from "react"
import styled from "styled-components"
import SectionHeading from "./styling/section-heading"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { device } from "./styling/device"

const StyledSection = styled.div`

`

const StyledTextAndImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

const StyledTextContainer = styled.div`
  max-width: 450px;
  font-size: 1rem;

  @media ${device.tablet} {
    margin-bottom: 50px;
  }
`

const StyledGatsbyImage = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 15px;
  width: 300px;
  height: 300px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09),
  0 4px 2px rgba(0, 0, 0, 0.09),
  0 8px 4px rgba(0, 0, 0, 0.09),
  0 16px 8px rgba(0, 0, 0, 0.09),
  0 32px 16px rgba(0, 0, 0, 0.09);
`

const About = ({ data }) => {
  const image = getImage(data.frontmatter.image)


  return (
    <StyledSection>
      <SectionHeading heading="About"
                      scrollId="about" />

      <StyledTextAndImageContainer>

        <StyledTextContainer>
          <MDXRenderer>
            {data.body}
          </MDXRenderer>
        </StyledTextContainer>

        <StyledGatsbyImage image={image} alt={"Tim Grein"} />

      </StyledTextAndImageContainer>

    </StyledSection>)
}

export default About
