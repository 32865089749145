import React from "react"
import styled from "styled-components"

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const StyledButton = styled.button`
  margin: 15px 15px;
`

const ProjectKeywordButtons = ({ keywords, handleKeywordClick, activeKeyword }) => (

  <StyledButtonContainer>
    {keywords.map(keyword => (
      <StyledButton
              key={keyword}
              onClick={() => handleKeywordClick(keyword)}
              className={keyword === activeKeyword ? "gradient-button" : ""}>
        {keyword}
      </StyledButton>))}
  </StyledButtonContainer>
)

export default ProjectKeywordButtons
