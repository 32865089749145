import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCertificate, faPenFancy } from "@fortawesome/free-solid-svg-icons"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Signature from "../signature"
import { device } from "../../styling/device"
import { getCurrentMonthAndYear } from "../../../utils/TimeUtils"

const StyledCertificateOuterContainer = styled.div`
  max-width: 500px;
  border: 3px solid var(--primary);
  padding: 2px;
  border-radius: 15px;
  text-align: center;
`

const StyledCertificateContainer = styled.div`
  padding: 35px;
  border: 3px solid var(--primary);
  border-radius: 10px;

  .icon {
    font-size: 45px;
    color: var(--primary);
  }
  
  @media ${device.mobileL}{
    padding: 35px 10px;
  }
`

const StyledContentContainer = styled.div`
  text-align: left;
`

const StyledSignatureContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
`


const Certificate = ({ data }) => {

  return (
    <StyledCertificateOuterContainer>
      <StyledCertificateContainer>

        <FontAwesomeIcon className="icon" icon={faCertificate} />

        <h4>Certificate ({data.frontmatter.station})</h4>

        <h3 class="gradient-text">{data.frontmatter.title}</h3>

        <h4>Content:</h4>

        <StyledContentContainer>
          <MDXRenderer>
            {data.body}
          </MDXRenderer>
        </StyledContentContainer>

        <StyledSignatureContainer>
          <Signature content={getCurrentMonthAndYear()} icon={faPenFancy} />
        </StyledSignatureContainer>

      </StyledCertificateContainer>
    </StyledCertificateOuterContainer>
  )
}

export default Certificate
