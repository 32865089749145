import React, { useState } from "react"
import SectionHeading from "../styling/section-heading"
import ProjectCard from "./project-card"
import ProjectKeywordButtons from "./project-keyword-buttons"
import ProjectCounter from "./project-counter"
import useMediaQuery from "../styling/use-media-query-hook"
import { device } from "../styling/device"

const Projects = ({ projects }) => {
  const isMobileOrTablet = useMediaQuery(device.tablet)

  const [state, setState] = useState({
    activeKeyword: "C++"
  })

  const handleKeywordClick = (keyword) => {
    setState({
      ...state,
      activeKeyword: keyword
    })
  }

  const allKeywords = []

  projects.nodes.forEach(node =>
    node.frontmatter.keywords.forEach(keyword => {
      if (allKeywords.indexOf(keyword) === -1) {
        allKeywords.push(keyword)
      }
    })
  )

  allKeywords.sort()

  const projectsToRender = projects.nodes.filter(node => node.frontmatter.keywords.includes(state.activeKeyword))

  return (
    <div>
      <SectionHeading heading={"Projects"}
                      scrollId="projects" />

      <ProjectCounter numberOfCurrentProjects={projectsToRender.length}
                      numberOfAllProjects={projects.nodes.length}/>

      {allKeywords && <ProjectKeywordButtons
        keywords={allKeywords}
        handleKeywordClick={handleKeywordClick}
        activeKeyword={state.activeKeyword} />}

      {projectsToRender.map((project, index) => {
          const isEven = !isMobileOrTablet ? index % 2 : true;

          return (
            <ProjectCard key={index}
                         projectData={project}
                         imageRight={!isEven}>
            </ProjectCard>
          )
        }
      )}

    </div>
  )
}

export default Projects
