import React from 'react';
import styled from "styled-components"

const StyledHeadingContainer = styled.div`
  text-align: center;
  padding-bottom: 25px;
`

const SectionHeading = ({heading, scrollId} ) => {
  return (
    <StyledHeadingContainer>
      <h2 id={scrollId}>{heading}</h2>
    </StyledHeadingContainer>

  )
}

export default SectionHeading;
