import React from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledSignatureContainer = styled.span`
  font-family: 'Carattere', cursive;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid lightgray;
  margin: 0 auto;
  
  .margin-right {
    margin-right: 10px;
  }
`

const Signature = ({content, icon}) => (
  <StyledSignatureContainer>
    {icon &&<FontAwesomeIcon className="margin-right" icon={icon} color="gray"/>}
    <span>{content}</span>
  </StyledSignatureContainer>
)

export default Signature;
