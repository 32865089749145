import React from "react"
import SecureNewTabLink from "./secure-new-tab-link"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageWithLink = ({ image, alt, destination, style }) => (
  <SecureNewTabLink destination={destination}>
    <GatsbyImage alt={alt} image={image} style={style} />
  </SecureNewTabLink>
)

export default ImageWithLink
